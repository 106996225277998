.mobile__calendar .header > .left,
.mobile__calendar .header > .right {
  display: none !important;
}

.mobile__calendar ~ .confirm-panel {
  display: none !important;
}

.calendar__modal {
  z-index: 1002 !important;
}
